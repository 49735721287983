import { Avatar, Button, Input, useToast } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Settings() {

  const userData = JSON.parse(localStorage.getItem('user'));

  const toast = useToast();
  const id = "toast";

  const navigate = useNavigate();

  const [UserName, setUserName] = useState(userData.phone);
  const [CurrentPassword, setCurrentPassword] = useState();
  const [NewPassword, setNewPassword] = useState();


  const HandleChangePassword = async(e) =>{

    e.preventDefault();

    let body = {
      username: UserName,
      currentPassword: CurrentPassword,
      newPassword: NewPassword
  }

  await axios
  .post(`${process.env.REACT_APP_AHMS_URL}/api/changepassword`, body)
  .then((response) => {
    console.log("Register Response", response);

    const res = response.data;

    if (!toast.isActive(id)) {
      toast({
        id,
        title: response.data,
        position: "top",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
    }

    if(res === 'Password Changed Successfully'){

      navigate("/");

      setCurrentPassword('');
      setNewPassword('');

    }


  })
  .catch((error) => {
    console.log("Register Error", error);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: error.message,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  });

  }


  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-6">
        <h1 className="text-xl font-bold text-gray-800">Settings</h1>
      </div>

      <div className='grid grid-cols-2 gap-6'>

        <div className='bg-white p-4 rounded-2xl'>
         <div className="border-b border-slate-400 mb-6">
           <h1 className="text-xl font-bold text-gray-800">Your Profile</h1>
         </div>

         <div className='flex gap-6 items-center'>
          <div className='w-1/4'>
            <Avatar name={userData.name} size={'2xl'} />
          </div>
          <div className='space-y-2'>
            <h6 className='font-semibold text-lg'>Name : <span className='font-normal'>{userData.name}</span></h6>
            <h6 className='font-semibold text-lg'>Phone : <span className='font-normal'>{userData.phone}</span></h6>
            <h6 className='font-semibold text-lg'>Email : <span className='font-normal'>{userData.email}</span></h6>

          </div>

         </div>
        </div>

        <div className='bg-white p-4 rounded-2xl'>
         <div className="border-b border-slate-400 mb-6">
           <h1 className="text-xl font-bold text-gray-800">Change Password</h1>
         </div>
         <form onSubmit={HandleChangePassword} className='space-y-3 max-w-md mx-auto'>

          <div className='space-y-2'>
            <label>Email or Phone</label>
            <Input isRequired value={UserName} isDisabled onChange={({target})=>{setUserName(target.value)}} borderColor={'gray.500'} placeholder='Enter Email or Phone' />
          </div>


          <div className='space-y-2'>
            <label>Current Password</label>
            <Input isRequired  value={CurrentPassword || ''} onChange={({target})=>{setCurrentPassword(target.value)}}  type={'password'} borderColor={'gray.500'} placeholder='Current Password' />
          </div>

          <div className='space-y-2'>
            <label>New Password</label>
            <Input isRequired  value={NewPassword || ''} onChange={({target})=>{setNewPassword(target.value)}}  type={'password'} borderColor={'gray.500'} placeholder='Enter Email or Phone' />
          </div>

          <div >
            <Button type='submit' className='w-full mt-2' colorScheme={'purple'}>Change Password</Button>
          </div>

         </form>
        </div>

      </div>
      
    </div>
  )
}

export default Settings