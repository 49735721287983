import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Customers from './Components/Customers';
import Settings from './Components/Settings';
import Dashboard from './Dashboard'


function MainRoutes() {
  return (
    <div>
      <Routes>
       <Route exact path='/dashboard' element={<Dashboard/>}/>             
       <Route exact path='/customers' element={<Customers/>}/>
       <Route exact path='/settings' element={<Settings/>}/>
      </Routes>
    </div>
  )
}

export default MainRoutes