import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Eye, EyeOff } from "react-feather";

function Login() {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const toast = useToast();
  const id = "toast";

  const [RegName, setRegName] = useState();
  const [RegEmail, setRegEmail] = useState();
  const [RegPhone, setRegPhone] = useState();
  const [RegPassword, setRegPassword] = useState();
  const [Charcters, setCharcters] = useState(false);

  // Login States
  const [signemail, setSignEmail] = useState("");
  const [signpassword, setSignPassword] = useState("");
  const navigate = useNavigate();

  //  console.log(process.env.REACT_APP_AHMS_URL);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let body = {
      username: signemail,
      password: signpassword,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_AHMS_URL}/api/login`,
      body
    );
    console.log(response);
    const res = response.data;

    if (res.message === "Login Successful") {
      navigate("/dashboard");

      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(res));
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          title: res.message,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const HandleRegister = async (e) => {
    e.preventDefault();


    if(!Charcters){

      let body = {
        name: RegName,
        email: RegEmail,
        phone: Number(RegPhone),
        password: RegPassword,
      };
  
      console.log("Register Body", body);
  
      await axios
        .post(`${process.env.REACT_APP_AHMS_URL}/api/register`, body)
        .then((response) => {
          console.log("Register Response", response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: response.data,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
  
          setRegName("");
          setRegEmail("");
          setRegPhone("");
          setRegPassword("");
        })
        .catch((error) => {
          console.log("Register Error", error);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: error.message,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

    }else{
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "Your Password is Too Short!!",
          description:"Please Enter the Required Length!",
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }


  };

  return (
    <div className="h-screen drop-shadow-md bg-gradient-to-br from-pink-300 via-purple-300 to-indigo-400 flex">
      <div className="bg-white rounded-3xl mx-auto  max-w-3xl w-full my-auto grid grid-cols-1 md:grid-cols-2 ">
        <div className="flex rounded-l-3xl bg-gradient-to-br from-indigo-200 via-red-200 to-yellow-100 p-4">
          <div className="my-auto mx-auto">
            <img src="/ShruckenLogo.png" className="h-64" alt="Shrucken-Logo" />

            <div className="text-center">
            <h6 className="text-red-500 text-3xl  font-bold italic ">SCMS</h6>
            </div>

          </div>
        </div>
        <div className=" rounded-r-3xl">
          <Tabs size="lg" defaultIndex={1} isFitted>
            <TabList>
              <Tab>Sign Up</Tab>
              <Tab>Sign In</Tab>
            </TabList>
            <TabPanels>
              {/* Sign Up Form */}
              <TabPanel>
                <form className="space-y-2" onSubmit={HandleRegister}>
                  <div className="space-y-2">
                    <label>Name</label>
                    <Input
                      isRequired
                      value={RegName || ""}
                      onChange={({ target }) => {
                        setRegName(target.value);
                      }}
                      borderColor="gray.500"
                      placeholder="Enter Name"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Email Address</label>
                    <Input
                      isRequired
                      value={RegEmail || ""}
                      onChange={({ target }) => {
                        setRegEmail(target.value);
                      }}
                      type="email"
                      borderColor="gray.500"
                      placeholder="Enter Email Address"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Phone No.</label>
                    <Input
                    isRequired
                      value={RegPhone || ""}
                      onChange={({ target }) => {
                        setRegPhone(target.value);
                      }}
                      type="number"
                      borderColor="gray.500"
                      placeholder="Enter Mobile Number"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Password</label>
                    <InputGroup size="md">
                      <Input
                      isRequired
                        value={RegPassword || ""}
                        onChange={({ target }) => {
                           setRegPassword(target.value);
                          if (target.value.length <= 6) { 
                            setCharcters(true);
                        }else{
                          setCharcters(false);
                        }
                        }}
                        pr="4.5rem"
                        borderColor="gray.500"
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                      />
                      <InputRightElement width="2.5rem">
                        <Button h="1.75rem" size="xs" onClick={handleClick}>
                          {show ? <EyeOff size="14px" /> : <Eye size="14px" />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {Charcters === true ? <span className="text-xs text-red-600">* Minimum 6 Characters</span> :""}
                  </div>

                  <div>
                    <Checkbox borderColor="gray.500">
                      I accept the Terms & Conditions
                    </Checkbox>
                  </div>

                  <div>
                    <Button
                      type="submit"
                      className="w-full"
                      colorScheme="purple"
                      variant="solid"
                    >
                      Register
                    </Button>
                  </div>
                </form>
              </TabPanel>

              {/* Sign In Form */}
              <TabPanel>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <div className="space-y-2">
                    <label>Email or Phone</label>
                    <Input
                    isRequired
                      value={signemail || ""}
                      onChange={({ target }) => {
                        setSignEmail(target.value);
                      }}
                      borderColor="gray.500"
                      placeholder="Enter Email or Phone"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Password</label>
                    <InputGroup size="md">
                      <Input
                      isRequired
                        value={signpassword || ""}
                        onChange={(e) => {
                          setSignPassword(e.target.value);
                        }}
                        // pr="2rem"
                        borderColor="gray.500"
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                      />
                      <InputRightElement width="2.5rem">
                        <Button h="1.75rem" size="xs" onClick={handleClick}>
                          {show ? <EyeOff size="14px" /> : <Eye size="14px" />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </div>

                  <div className="flex justify-between">
                    <Checkbox borderColor="gray.500">Remember Me</Checkbox>

                    <Button colorScheme="blue" variant="link">
                      Forgot Password?
                    </Button>
                  </div>

                  <div>
                    <Button
                      type="submit"
                      className="w-full"
                      colorScheme="purple"
                      variant="solid"
                    >
                      Sign In
                    </Button>
                  </div>
                </form>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
    </div>

  );
}

export default Login;
