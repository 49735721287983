import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import {
  Input,
  Select,
  Textarea,
  Button,
  useToast,
  Checkbox,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Download, Edit, Plus } from "react-feather";
import axios from "axios";


function Customers() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [DateOfBirth, setDateOfBirth] = useState();

  const [IsEdit, setIsEdit] = useState(false);

  const [CustId, setCustId] = useState(0);

  const [CardNo, setCardNo] = useState();
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [Gender, setGender] = useState();
  const [Occupation, setOccupation] = useState();
  const [Age, setAge] = useState();
  const [StaffName, setStaffName] = useState();
  const [Location, setLocation] = useState();
  const [ContactNo, setContactNo] = useState();
  const [WhatsAppNo, setWhatsAppNo] = useState();
  const [EmailId, setEmailId] = useState();
  const [Category, setCategory] = useState();
  const [Description, setDescription] = useState();
  const [Issue, setIssue] = useState();

  const [EntryDate, setEntryDate] = useState(new Date().toLocaleDateString('en-CA'));
  const [DueAmount, setDueAmount] = useState();
  const [CustomerType, setCustomerType] = useState();
  const [CustomerSource, setCustomerSource] = useState();

  const [Address, setAddress] = useState();
  const [IsInterested, setIsInterested] = useState(false);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Card No.",
      field: "cardNo",
    },
    {
      headerName: "Cust. Name",
      valueGetter: (params) =>
        params.data.firstName + " " + params.data.lastName,
    },
    {
      headerName: "Gender",
      field: "gender",
    },
    {
      headerName: "Is Interested",
      field: "isInterested",
      cellRenderer:(params)=>(
        params.data.isInterested === true ?
        <span className="bg-green-600 px-1.5 py-0.5 rounded-md text-white font-semibold">
          Interested
        </span> :
        <span className="bg-red-600 px-1.5 py-0.5 rounded-md text-white font-semibold">
          Not Interested
        </span>
      )
    },
    {
      headerName: "Staff Name",
      field: "staffName",
    },
    {
      headerName: "Category",
      field: "category",
    },
    {
      headerName: "Contact No.",
      field: "contactNo1",
    },
    {
      headerName: "WhatsApp No.",
      field: "contactNo2",
    },
    {
      headerName: "Email Id",
      field: "emailId",
    },
    {
      headerName: "City",
      field: "city",
    },
    {
      headerName: "Issue",
      field: "issue",
    },
    {
      headerName: "Entry Date",
      field: "entryDate",
    },
    {
      headerName: "Due Amount",
      field: "dueAmount",
    },
    {
      headerName: "Customer of",
      field: "customerType",
    },
    {
      headerName: "Customer Source",
      field: "customerSource",
    },
    //   { headerName: "Is Available", field: "isAvailable" ,
    //    cellRenderer:(params) => <Switch colorScheme='green' isChecked={ params.data.isAvailable}  size='md' />
    // },
    {
      headerName: "Action",
      field: "guId",
      pinned: 'right',
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {

              let custData=params.data;

              setCustId(params.data._id)

              setCardNo(custData.cardNo)
              setFirstName(custData.firstName)
              setLastName(custData.lastName)
              setGender(custData.gender)
              setOccupation(custData.occupation)
              setAge(custData.age)
              setStaffName(custData.staffName)
              setLocation(custData.city)
              setContactNo(custData.contactNo1)
              setWhatsAppNo(custData.contactNo2)
              setEmailId(custData.emailId)
              setCategory(custData.category)
              setDescription(custData.description)
              setIssue(custData.issue)
              setAddress(custData.address)
              setIsInterested(custData.isInterested)
              setDateOfBirth(new Date(custData.dateOfBirth).toLocaleDateString("en-CA"));
              setEntryDate(new Date(custData.entryDate).toLocaleDateString("en-CA"));
              setDueAmount(custData.dueAmount);
              setCustomerType(custData.customerType);
              setCustomerSource(custData.CustomerSource);

              setIsEdit(true);
              onOpen();
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  console.log("Customer ID",CustId);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable:true
  }));

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  useEffect(() => {
    GetCustomerList();
  }, []);

  const GetCustomerList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AHMS_URL}/api/shrucken/getCustomerList/${userData.phone}`
      );
      console.log("Get Customer List Response", response);
      const res = response.data
      setRowData(res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    } catch (error) {
      console.log(error);
    }
  };

  const AddCustomer = async (e) => {
    e.preventDefault();

    let body = {
      userId:CustId,
      cardNo: CardNo,
      firstName: FirstName,
      lastName: LastName,
      dateOfBirth: DateOfBirth,
      gender: Gender,
      age: Age,
      issue: Issue,
      address: Address,
      isInterested: IsInterested,
      staffName: StaffName,
      city: Location,
      occupation:Occupation,
      contactNo1: ContactNo,
      contactNo2: WhatsAppNo,
      emailId: EmailId,
      category: Category,
      description: Description,
      createdBy: userData.phone,
      entryDate: EntryDate,
      dueAmount: DueAmount,
      customerType: CustomerType,
      customerSource: CustomerSource
    };

    console.log("Add Customer Body", body);

    await axios
      .post(`${process.env.REACT_APP_AHMS_URL}/api/shrucken/addCustomer`, body)
      .then((response) => {
        console.log("Register Response", response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetCustomerList();


        if(res === "Customer Added Successfully"  || res === "Customer Updated Successfully" ){

          setDateOfBirth("");
          setCardNo("");
          setFirstName("");
          setLastName("");
          setGender("");
          setOccupation("");
          setIsInterested(false);
          setAddress('');
          setAge("");
          setCustId(0);
          setStaffName("");
          setLocation("");
          setContactNo("");
          setWhatsAppNo("");
          setEmailId("");
          setCategory("");
          setDescription("");
          setIssue("");
          setEntryDate(new Date().toLocaleDateString('en-CA'));
          setDueAmount('');
          setCustomerType('');
          setCustomerSource('')
          setIsEdit(false);
          onClose();

        }

      })
      .catch((error) => {
        console.log("Register Error", error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: error.data,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
            <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Customers</h1>

        <div className="space-x-4">
          <Button size="sm" colorScheme="purple" onClick={onBtnExport}>
            <Download size={'18px'} className="mr-1" />Export CSV
          </Button>

          <Button size="sm" colorScheme="purple" onClick={onOpen}>
            <Plus size={'18px'} className="mr-1" />Add Customer
          </Button>
        </div>

      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Modal size={'6xl'} isOpen={isOpen} onClose={()=>{
                  setDateOfBirth("");
                  setCardNo("");
                  setFirstName("");
                  setLastName("");
                  setGender("");
                  setOccupation("");
                  setIsInterested(false);
                  setAddress('');
                  setAge("");
                  setCustId(0);
                  setStaffName("");
                  setLocation("");
                  setContactNo("");
                  setWhatsAppNo("");
                  setEmailId("");
                  setCategory("");
                  setDescription("");
                  setIssue("");
                  setIsEdit(false);

                  onClose();
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{IsEdit === true ? 'Update Customer' : 'Add Customer'}</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={AddCustomer}>
          <ModalBody>

          <div className="text-right mt-2">
                <Checkbox isChecked={IsInterested} onChange={({target})=>{setIsInterested(target.checked)}} borderColor={'gray.500'}>Is Interested</Checkbox>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 my-4">
                <div className="space-y-2">
                  <label>Card No.</label>
                  <Input
                    
                    disabled={IsEdit}
                    value={CardNo || ""}
                    onChange={({ target }) => {
                      setCardNo(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Card No."
                  />
                </div>

                <div className="space-y-2">
                  <label>First Name</label>
                  <Input
                   
                    value={FirstName || ""}
                    onChange={({ target }) => {
                      setFirstName(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="First Name"
                  />
                </div>

                <div className="space-y-2">
                  <label>Last Name</label>
                  <Input
                   
                    value={LastName || ""}
                    onChange={({ target }) => {
                      setLastName(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Last Name"
                  />
                </div>

                <div className="space-y-2">
                  <label>Date of Birth</label>
                  <Input
                    
                    value={DateOfBirth || ""}
                    onChange={({ target }) => {
                      setDateOfBirth(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Date Of Birth"
                    type={"date"}
                  />

                  {/* <DatePicker
                       className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                       selected={DateOfBirth}
                       onChange={(date) => setDateOfBirth(date)}
                       placeholderText="End Date"
                     /> */}
                </div>

                <div className="space-y-2">
                  <label>Gender</label>
                  <Select
                   
                    value={Gender}
                    onChange={({ target }) => {
                      setGender(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Select Gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label>Occupation</label>
                  <Input
                    value={Occupation || ""}
                    onChange={({ target }) => {
                      setOccupation(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Occupation"
                  />
                </div>

                <div className="space-y-2">
                  <label>Age</label>
                  <Input
                    
                    value={Age || ""}
                    onChange={({ target }) => {
                      setAge(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Age"
                    type={"number"}
                  />
                </div>

                <div className="space-y-2">
                  <label>Staff Name</label>
                  <Input
                    value={StaffName || ""}
                    onChange={({ target }) => {
                      setStaffName(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Staff Name"
                  />
                </div>

                <div className="space-y-2">
                  <label>Location</label>
                  <Input
                    value={Location || ""}
                    onChange={({ target }) => {
                      setLocation(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Location"
                  />
                </div>

                <div className="space-y-2">
                  <label>Contact No.</label>
                  <Input
                   
                    value={ContactNo || ""}
                    onChange={({ target }) => {
                      setContactNo(target.value);
                    }}
                    type={"number"}
                    borderColor="gray.500"
                    placeholder="Contact No."
                  />
                </div>

                <div className="space-y-2">
                  <label>WhatsApp No.</label>
                  <Input
                    value={WhatsAppNo || ""}
                    onChange={({ target }) => {
                      setWhatsAppNo(target.value);
                    }}
                    type={"number"}
                    borderColor="gray.500"
                    placeholder="WhatsApp No."
                  />
                </div>

                <div className="space-y-2">
                  <label>E-mail Id</label>
                  <Input
                    value={EmailId || ""}
                    onChange={({ target }) => {
                      setEmailId(target.value);
                    }}
                    type={"email"}
                    borderColor="gray.500"
                    placeholder="E-mail Id"
                  />
                </div>
                
                <div className="space-y-2">
                  <label>Entry Date</label>
                  <Input
                    value={EntryDate || ""}
                    onChange={({ target }) => {
                      setEntryDate(target.value);
                    }}
                    type={"date"}
                    borderColor="gray.500"
                    placeholder="Entry Date"
                  />
                </div>

                <div className="space-y-2">
                  <label>Due Amount</label>
                  <Input
                    value={DueAmount || ""}
                    onChange={({ target }) => {
                      setDueAmount(target.value);
                    }}
                    type={"text"}
                    borderColor="gray.500"
                    placeholder="Due Amount"
                  />
                </div>

                <div className="space-y-2">
                  <label>Customer of</label>
                  <Input
                    value={CustomerType || ""}
                    onChange={({ target }) => {
                      setCustomerType(target.value);
                    }}
                    type={"text"}
                    borderColor="gray.500"
                    placeholder="Customer of"
                  />
                </div>

                <div className="space-y-2">
                  <label>Customer Source</label>
                  <Input
                    value={CustomerSource || ""}
                    onChange={({ target }) => {
                      setCustomerSource(target.value);
                    }}
                    type={"text"}
                    borderColor="gray.500"
                    placeholder="Customer Source"
                  />
                </div>

                <div className="space-y-2">
                  <label>Category</label>
                  <Select 
                    
                    value={Category}
                    onChange={({ target }) => {
                      setCategory(target.value);
                    }}
                    borderColor="gray.500"
                    placeholder="Select Category"
                  >
                    <option value="Single">Single</option>
                    <option value="Couple">Couple</option>
                    <option value="Purchase Enquiry">Purchase Enquiry</option>
                    <option value="After Service">After Service</option>
                    <option value="Therapy Centre">Therapy Centre</option>
                    <option value="Old Machine Sale">Old Machine Sale</option>
                    <option value="Already Purchased">Already Purchased</option>
                    <option value="Rental">Rental</option>
                    <option value="Pain Oil">Pain Oil</option>
                    <option value="Hair Oil">Hair Oil</option>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label>Description</label>
                  <Textarea
                    value={Description || ""}
                    onChange={({ target }) => {
                      setDescription(target.value);
                    }}
                    rows={2}
                    borderColor="gray.500"
                    placeholder="Description"
                  />
                </div>

                <div className="space-y-2">
                  <label>Issue</label>
                  <Textarea
                    value={Issue || ""}
                    onChange={({ target }) => {
                      setIssue(target.value);
                    }}
                    rows={2}
                    borderColor="gray.500"
                    placeholder="Issue"
                  />
                </div>

                <div className="space-y-2">
                  <label>Address</label>
                  <Textarea
                    value={Address}
                    onChange={({target})=>{setAddress(target.value)}}
                    rows={2}
                    borderColor="gray.500"
                    placeholder="Address"
                  />
                </div>


              </div>
           
          </ModalBody>
<hr />
          <ModalFooter>
          <Button type="submit" variant='solid' colorScheme={'purple'} mr={3}>{ IsEdit === true ? 'Update' : 'Save'}</Button>

            <Button variant={'outline'} colorScheme='purple' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>


    </div>
  );
}

export default Customers;
