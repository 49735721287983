import React from "react";
import Notifications from "./Notifications";
import UserMenu from "./UserMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

function Header({ sidebarOpen, setSidebarOpen }) {

  return (
    <header className="sticky top-2 mx-2 rounded-2xl bg-slate-800 border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-white mr-3 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>


            <div className="flex  text-white items-center">
            {/* <img src="/ShruckenLogo.png" className="h-9 mr-1" alt="Shrucken-Logo" /> */}
            <FontAwesomeIcon icon={faBuilding} className='mr-2'/>

              <h1 className="font-semibold text-lg">SCMS</h1>
            </div>


          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <Notifications />
            <hr className="w-px h-6 bg-slate-200 mx-3" />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
