import React, { useEffect,useState } from 'react';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import MainRoutes from './MainRoutes';
import Login from './Login';
import Header from './UI Components/Header';
import Sidebar from './UI Components/Sidebar'

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change


  return (
    <div>
      
{(location.pathname === "/") ? (
        <Routes>
          <Route exact path="/" element={<Login />} />
        </Routes>
      ) : (


      <div className='flex h-screen bg-slate-300 overflow-hidden'>

        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />


        <div className="relative flex flex-col no-scrollbar flex-1 overflow-y-auto overflow-x-hidden">

          <Header  sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main className='no-scrollbar overflow-auto'>
            <div className="px-3   py-8 w-full max-w-10xl mx-auto">
              <MainRoutes/>
            </div>
          </main>


        </div>
      </div> )}
    </div>
  );
}

export default App;
